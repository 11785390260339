import { m } from "framer-motion";
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import LinearProgress from "@mui/material/LinearProgress";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }: { theme: Theme }) => ({
  width: "100%",
  height: "100vh", // Full viewport height
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
}));

const MusicNoteAnimation = styled(m.div)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "4rem",
  color: "#FD934C", // Color of the animated icon
  animation: "spin 3s linear infinite", // Adding a spinning animation
}));

const ProgressBarWrapper = styled("div")(({ theme }: { theme: Theme }) => ({
  width: "50%", // Adjust width as necessary
  marginTop: theme.spacing(4), // Space between the icon and progress bar
}));

const GradientProgressBar = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300], // Background color of the progress bar
  "& .MuiLinearProgress-bar": {
    background: "linear-gradient(90deg, rgba(255,169,249,1) 0%, rgba(255,249,121,1) 100%, rgba(255,247,173,1) 100%)",
    animation: "loading-animation 3s infinite", // Adding a loading animation
  },
}));

// Adding keyframes for custom animations
const GlobalStyles = styled("style")`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes loading-animation {
    0% { transform: translateX(-100%); }
    50% { transform: translateX(0); }
    100% { transform: translateX(100%); }
  }
`;

// ----------------------------------------------------------------------

interface Props {
  isDashboard?: boolean;
  sx?: SxProps<Theme>;
}

const LoadingScreen: React.FC<Props> = ({ isDashboard, ...other }) => (
  <RootStyle {...other}>
    <GlobalStyles />
    <MusicNoteAnimation
      initial={{ y: -20 }}
      animate={{ y: 20 }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      <MusicNoteIcon fontSize="inherit" />
    </MusicNoteAnimation>

    <ProgressBarWrapper>
      <GradientProgressBar
        variant="indeterminate"
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundImage: "linear-gradient(90deg, rgba(255,169,249,1) 0%, rgba(255,249,121,1) 100%, rgba(255,247,173,1) 100%)",
          },
        }}
      />
    </ProgressBarWrapper>
  </RootStyle>
);

export default LoadingScreen;
