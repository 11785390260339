/* eslint-disable import/no-duplicates */
import React from "react";
import { Container, Typography, List, ListItem, ListItemText, Box } from "@mui/material";

const PrivacyPolicy: React.FC = () => (
    <Container sx={{ mt: 20 }}>
      <Typography variant='h1' component='h1' gutterBottom sx={{ fontSize: "2rem" }}>
        Privacy Policy
      </Typography>
      <Typography variant='body1' gutterBottom sx={{ fontSize: "1rem" }}>
        <strong>Effective Date: June 1, 2024</strong>
      </Typography>
      <Typography variant='body1' paragraph sx={{ fontSize: "1rem" }}>
        SOUNDCTRL.xyz (“SoundCtrl,” “we,” “us,” or “our”) respects your privacy. This Privacy Policy
        describes the types of Personal Information we collect when you interact with us, including
        but not limited to information provided when you access our websites (including
        www.soundctrl.xyz) and however else you may interact with us (collectively, the “Service” or
        “SoundCtrl Service”). This Policy applies to all SOUNDCTRL Tech INC, regardless of the
        platform or device used; it describes all Personal Information collected, used, transmitted,
        disclosed, stored, or maintained by us, including both electronic and paper records.
      </Typography>
      <Typography variant='body1' paragraph sx={{ fontSize: "1rem" }}>
        Please read this Policy carefully to fully understand how we collect, use, share, and
        protect your Personal Information. If you do not agree with this Privacy Policy, please do
        not use our Services.
      </Typography>
      <Section title='This Privacy Policy Explains:' fontSize='1.5rem'>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontWeight: "bold" }}>
                  - What information may be used when you collect this service
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontWeight: "bold" }}>
                  - Our use of cookies and other technology on our web-based Services
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontWeight: "bold" }}>
                  - How we may use and share information we collect about you
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontWeight: "bold" }}>
                  - The choices we offer, including how to access and update information
                </span>
              }
            />
          </ListItem>
        </List>
      </Section>
      <Section title='Information We Collect and Use' fontSize='1.5rem'>
        <Section title='Information You Give Us' fontSize='1.25rem'>
          <Typography variant='body1' paragraph sx={{ fontSize: "1rem" }}>
            When you sign up for and use our Services as a fan or artist (including when you use our
            online chatbot), we may collect and store Personal Information you provide, including:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Personal Identifiers:</span>}
                secondary='We receive personal identifiers you provide (such as your name, email address, mailing address, telephone number, signature, or similar identifiers). We use this information to respond to and complete your requests, communicate with you for registration, customer service, and informational purposes, and for marketing purposes. If you win a prize on the site, we may use your address for shipping purposes. We may also create internal identification and reference codes to identify you in our electronic systems.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>Communications and Submissions:</span>
                }
                secondary={
                  <>
                    We collect the information and content you submit when you:
                    <List>
                      <ListItem>
                        <ListItemText primary='- Submit questions' />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary='- Make calls with artists' />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary='- Respond to questions as an artist' />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary='- Post information on our websites (including public testimonials and forum posts)' />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary='- Voluntarily communicate with SoundCtrl' />
                      </ListItem>
                    </List>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>SOUNDCTRL Referral Information:</span>
                }
                secondary='If you use our referral program to recommend potential artists, tell a friend about SoundCtrl, or send a copy of a call to a friend, you may need to provide contact information for yourself and the friend. We will automatically send your friend a one-time email inviting them to visit the Sites. SoundCtrl stores this information for the sole purpose of sending this one-time email and tracking the success of our referral program.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Financial Information:</span>}
                secondary='We receive financial information such as payment card or PayPal™ information, and other related information to process payments for products and services you purchase and to issue artists and referral payments to you.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Commercial Information:</span>}
                secondary='We collect and generate commercial information and transaction information (such as records of products and services purchased on our websites or services you have enrolled in). We use this information to respond to and fulfill requests, improve our product and service offerings, communicate with you, tailor our marketing efforts, and for internal reporting purposes.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>
                    Inferences from the information listed above:
                  </span>
                }
                secondary='We will use the information listed above to draw inferences about your preferences to help us develop and provide better products and services.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Audio and Visual Information:</span>}
                secondary='We receive any digital photos you share with us through our Services. We may also record customer service telephone calls for quality purposes and to meet our legal obligations. We will record video calls between you and artists.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Privacy Choices:</span>}
                secondary='We receive and retain any opt-outs or other choices related to how we collect, use, and disclose Personal Information.'
              />
            </ListItem>
          </List>
        </Section>
        <Section title='Information Third Parties Provide About You.' fontSize='1.5rem'>
          <Typography variant='body1' paragraph sx={{ fontSize: "1rem" }}>
            We may, from time to time, supplement the information we collect about you online with
            outside records from third parties in order to enhance our ability to serve you, to
            tailor our content to you and to offer you opportunities to purchase products or
            services that we believe may be of interest to you. These third party sources include:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>
                    Social Networks and Publicly Available Data:
                  </span>
                }
                secondary='Our websites include social media features, such widgets like the “Share” button and similar functions. These features may collect your IP address, the pages you are visiting on our websites, and may set cookies to enable them to function as you have requested. Social media features and widgets are either hosted by a third party or directly on our websites.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>
                    Information That Is Collected from Marketing Partners and Data Providers
                  </span>
                }
                secondary='OWe may collect information about you from marketing partners and data providers, including but not limited to, personal identifiers, commercial information, and generally available information that helps us offer you products and services we think may interest you.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>Information Collected from Affiliates.</span>
                }
                secondary='We may collect information about you from SOUNDCTRL affiliates’ websites and other places where we show you ads. We may collect information from these services such as personal identifiers, commercial information, and other generally available information.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <span style={{ fontWeight: "bold" }}>
                    Information Other Users Provide about You.
                  </span>
                }
                secondary='Other users may provide us your personal identifiers (such as your email address) or other information in order to invite you to use the SOUNDCTRL Tech INC or share content from the SOUNDCTRL Tech INC with you. In this case, we will use the information for the purposes for which it was provided.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Other Information We Collect</span>}
                secondary='We also may collect other information about you, your device, or your use of the services in ways that we describe to you at the point of collection or otherwise with your consent. You may choose not to provide us with certain types of information, but doing so may affect your ability to use some of the SOUNDCTRL Tech INC.'
              />
            </ListItem>
          </List>
        </Section>
        <Section
          title='Information We Get from Your Use of The SOUNDCTRL Tech INC.'
          fontSize='1.5rem'
        >
          <Typography variant='body1' paragraph sx={{ fontSize: "1rem" }}>
            We collect information about the SOUNDCTRL Tech INC that you use and how you use them,
            like when you open one of our emails or view and interact with our ads and content
            (collectively, “Usage Information”). We use this information to provide and improve our
            products and services, tailor your experience on and across our websites and other
            services, tailor our marketing efforts, and to create aggregate internal reports on
            website usage and activity, such as views of certain pages and content. Usage
            Information includes:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Other Information We Collect</span>}
                secondary={
                  <>
                    <p>
                      <strong>Device Information:</strong> We collect device-specific information
                      (such as your hardware model, operating system version, unique device
                      identifiers, and mobile network information, including phone number).
                    </p>
                    <p>
                      <strong>Browsing Information:</strong> This includes the URL that referred you
                      to the SOUNDCTRL Tech INC, the areas within the SOUNDCTRL Tech INC that you
                      visit, how long you spend on the SOUNDCTRL Devices, and the time of day of
                      your visit.
                    </p>
                    <p>
                      <strong>IP Address:</strong> An IP address is a string of numbers associated
                      with the device and/or local network you connect to or use to access the
                      Internet.
                    </p>
                    <p>
                      <strong>Location Information:</strong> Many mobile devices permit applications
                      to access real-time geolocation information. We may collect and use such
                      information with your consent when you use our websites. We use various
                      technologies to determine location, including IP address, GPS, and other
                      sensors that may, for example, provide us with information on nearby devices,
                      Wi-Fi access points and cell towers and provide geo-location information.
                    </p>
                    <p>
                      <strong>Inferences from the information listed above:</strong> We may use the
                      information listed above to draw inferences about your preferences to help us
                      provide you with personalized content and offers and help us develop and
                      provide better products and services.
                    </p>
                    <p>
                      <strong>
                        Automatic Collection of Information from Cookies and Other Technology:
                      </strong>{" "}
                      We and our partners use various technologies to collect and store information
                      when you visit the SOUNDCTRL Tech INC, which we use for the same purposes as
                      Usage Information and to help us improve our websites and communications. This
                      automatic information collection may include using cookies or similar
                      technologies to identify your browser or device.
                    </p>
                    <p>
                      <strong>Cookies:</strong> A cookie is a small file containing a string of
                      characters that is sent to your computer when you visit a website. When you
                      visit the website again, the cookie allows that site to recognize your
                      browser. Cookies may store user preferences and other information. You can
                      reset your browser to refuse all cookies or to indicate when a cookie is being
                      sent. However, some website features or services may not function properly
                      without cookies.
                    </p>
                    <p>
                      <strong>Mobile Identifiers:</strong> We may use software development kits
                      (“SDKs”) that make use of mobile advertising identifiers (i.e., IDFA on Apple
                      mobile devices or Advertising ID on Android mobile devices), which function
                      similarly to cookies on mobile devices. You may reset your IDFA or Advertising
                      ID in the settings on your mobile device.
                    </p>
                    <p>
                      <strong>Web Beacons:</strong> Small graphic images or other web programming
                      code called web beacons (also known as “1×1 GIFs,” “one pixel GIFs,” or “clear
                      GIFs”) may be included in our web pages and e-mail messages. Web beacons may
                      be invisible to you, but any electronic image or other web programming code
                      inserted into a web page or e-mail can act as a web beacon. Web beacons or
                      similar technologies may be used for a number of purposes, including, without
                      limitation, to count visitors to the SOUNDCTRL Tech INC, to monitor how users
                      navigate the SOUNDCTRL Tech INC, to make cookies more effective, and to
                      analyze how our emails, articles, and links were opened, viewed, and used.
                    </p>
                    <br />
                    <p>
                      ** Most browsers will tell you how to stop accepting new cookies, how to
                      receive notification of new cookies, and how to disable existing cookies. For
                      more information about cookies and how to disable them, or restrict the
                      categories of cookies you wish to accept, you can consult the information at
                      www.allaboutcookies.org/manage-cookies/.
                    </p>
                    <br />
                    <p>
                      Please note that rejecting cookies does not mean that you will no longer see
                      ads when you visit the SOUNDCTRL Tech INC. Also, without cookies, you may not
                      be able to take full advantage of all of the SOUNDCTRL Tech INC and some parts
                      of the SOUNDCTRL Tech INC may not function properly.
                    </p>
                    <br />
                    <p>
                      <strong>Anonymous or Aggregated Information:</strong> We de-identify or
                      aggregate data we receive and may use and disclose it for any business
                      purpose.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>How We Disclose Personal Information</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      <strong>Service Providers:</strong> We share any of the Personal information
                      described above with service providers (including but not limited to payment
                      processors, fraud detection vendors, service-related technology providers, and
                      survey administrators) that provide services to you and us, including to help
                      us maintain our databases and websites, administer emails, display content,
                      and analyze website usage activity. For example, we may provide your personal
                      identifiers and commercial information to communications and marketing vendors
                      so they can send you marketing communications and other communications on our
                      behalf. We require that service providers use Personal Information only for
                      the purposes specified in our agreements.
                    </p>

                    <p>
                      <strong>Our Partners From Whom You Have Request Information:</strong> We may
                      share your personal identifiers and commercial information with other
                      companies from whom you request to receive a specific product or service so
                      they can market their products or services to you. If you do not want your
                      Personal Information shared with these companies, you may contact us using the
                      information in our “How to Contact Us” section below.
                    </p>

                    <p>
                      <strong>Within Our Organization:</strong> We may share any of your Personal
                      Information with parents, subsidiaries, joint venture partners, and other
                      entities under common control subject to this Privacy Policy.
                    </p>

                    <p>
                      <strong>Social Networks:</strong> You may interact with certain features of
                      the SOUNDCTRL Tech INC that cause certain categories of Personal Information
                      to be published to your social networks. To understand the precise categories
                      of Personal Information shared in this manner, please review the privacy
                      policies of the relevant social networks and/or log out of the applicable
                      service before you use our online services.
                    </p>

                    <p>
                      <strong>Public Disclosures Using Non-Restricted Services:</strong> As
                      explained in the SOUNDCTRL Terms of Service, information provided by you in
                      the content of your calls, questions, answers, and other communications on our
                      websites (including testimonials and community forum posts you submit), in
                      artists profiles describing your experiences and qualifications, and in
                      signatures attached to postings on our websites ("Posts") may not be private
                      or confidential. Posts may be collected, copied, or used by others without
                      your or our knowledge, and the security and other protective provisions of
                      this Policy will not apply. Also, please keep in mind that publicly posting
                      your account or contact information may result in the transmission of
                      unsolicited messages from persons and entities over which we have no control.
                      Please exercise caution and good judgment when disclosing any Personal
                      Information to any public services, including in Posts on the SOUNDCTRL Tech
                      INC. Note that we cannot be responsible for any online postings by a family
                      member, friend, acquaintance or other person or entity outside of our control.
                      If you wish to update or delete a Post, you may contact us using the “How to
                      Contact Us” information below. In some cases, we may not be able to remove
                      your Personal Information, in which case we will let you know and explain why.
                    </p>

                    <p>
                      <strong>Sweepstakes, Contests, and Promotions:</strong> SOUNDCTRL may offer
                      sweepstakes, contests, and other promotions (“Promotion(s)”) that may require
                      registration. By participating in a Promotion, you are agreeing to the terms,
                      conditions or official rules that govern that Promotion, which may contain
                      specific requirements of you, including, except where prohibited by law,
                      allowing the sponsor(s) of the Promotion to use your name, voice and/or
                      likeness in advertising or marketing associated with the Promotion. If you
                      choose to enter a Promotion, Personal Information may be disclosed to third
                      parties or the public in connection with the administration of such Promotion,
                      including, without limitation, in connection with winner selection, prize
                      fulfillment, and as required by law or permitted by the Promotion’s official
                      rules, such as on a winners list.
                    </p>

                    <p>
                      <strong>Business Transfers:</strong> In the event of a merger, acquisition,
                      financing due diligence, reorganization, bankruptcy, receivership, sale of
                      company assets (whether by private sale, through operation of law, as part of
                      a divestiture plan, or otherwise), your Personal Information, and any other
                      information that we have collected, may be disclosed to the persons and/or
                      entities assuming control of the relevant business unit or as otherwise
                      necessary to complete the transaction as permitted by law or contract.
                    </p>

                    <p>
                      <strong>Legal and Similar Disclosures:</strong> We may disclose Personal
                      Information about you with law enforcement, the courts, our advisors,
                      attorneys, and others who participate in the legal process, if necessary to do
                      so by law or based on our good faith belief that it is necessary to enforce or
                      apply our contracts, conform or comply with the law or is necessary to protect
                      us, the users of the websites, or others.
                    </p>

                    <p>
                      <strong>Otherwise with Your Consent or At Your Direction:</strong> In addition
                      to the sharing described in this Privacy Policy, we may share information
                      about you with third parties whenever you consent to or direct such sharing.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>Opt-Out Choice</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      <strong>
                        We offer you choices regarding the collection, use, and sharing of your
                        Personal Information on the SOUNDCTRL Tech INC.
                      </strong>
                    </p>

                    <p>
                      <strong>Upon request:</strong> SOUNDCTRL will provide you with information
                      about whether we hold any of your Personal Information.
                    </p>

                    <p>
                      <strong>Email Communications:</strong> When you receive promotional
                      communications from us, you may indicate a preference to stop receiving
                      further promotional communications from us, and you will have the opportunity
                      to “opt-out” by following the unsubscribe instructions provided in the
                      promotional email you receive or by contacting us directly at{" "}
                      <a href='mailto:info@soundctrl.xyz'>info@soundctrl.xyz</a>. Regardless of your
                      indicated email preferences, we may send you administrative emails regarding
                      the SOUNDCTRL Tech INC, including, for example, notices of updates to our
                      online terms or this Privacy Policy, registration and artists
                      application-related communications (as appropriate), and communications and
                      updates related to questions you are asking or answering, Posts you make, and
                      other information related to your account.
                    </p>

                    <p>
                      <strong>Update or Delete Your Account Information:</strong> If you wish to
                      verify, correct, update or delete any of the Personal Information provided to
                      us in connection with your account on the SOUNDCTRL Tech INC, you may do so
                      through the Edit Profile page when you log in to your account. Please note
                      that we may be required (by applicable law or otherwise) to keep certain
                      information and not delete it, in which case we will comply with your deletion
                      request only after we have fulfilled such requirements. It may not always be
                      possible to completely remove or delete all of your information from our
                      databases without some residual data remaining in our archives because of
                      backups and other reasons. If you would like to make a request related to the
                      Personal Information we have about you, you may contact us at{" "}
                      <a href='mailto:info@soundctrl.xyz'>info@soundctrl.xyz</a>.
                    </p>

                    <p>
                      <strong>Opt-out of Personalized Advertising:</strong> We do not sell your
                      personal information to third parties, but if you do not wish for our
                      third-party partners to provide you with personalized advertising based on
                      your browsing activity and interest, you may opt-out of receiving such
                      advertisements on our websites by contacting us at{" "}
                      <a href='mailto:info@soundctrl.xyz'>info@soundctrl.xyz</a>.
                    </p>
                    <br />

                    <p>
                      Upon request, SOUNDCTRL will provide you with information about whether we
                      hold any of your Personal Information. You may also exercise your preferences
                      in the following manner:
                    </p>
                    <br />
                    <ul>
                      <li>
                        Visit the Digital Advertising Alliance (DAA) Opt-Out Page (
                        <a
                          href='http://www.aboutads.info/choices'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          http://www.aboutads.info/choices
                        </a>
                        ). The DAA Opt-Out Page provides a tool that identifies its member companies
                        that have Cookies on your browser and provides links to those companies.
                      </li>
                      <li>
                        Update the privacy settings in the Help section of your browser, or in the
                        Settings tab on your mobile device.
                      </li>
                      <li>You may also click on the Ad Choices icon in certain advertisements.</li>
                      <li>
                        You may opt out of additional third party advertising networks by going to
                        the Network Advertising Initiative’s Website (
                        <a
                          href='http://www.networkadvertising.org/managing/opt_out.asp'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          http://www.networkadvertising.org/managing/opt_out.asp
                        </a>
                        ) and following the directions provided.
                      </li>
                      <li>
                        Additionally, many network advertising programs allow you to view and manage
                        the interest categories they have compiled from your online browsing
                        activities. These interest categories help determine the types of
                        personalized advertisements you may receive.
                      </li>
                    </ul>
                    <br />
                    <p>
                      Please note that even if you choose to remove your information or “opt out,”
                      you may continue to see advertisements while you are browsing online; however,
                      those advertisements will no longer be tailored to your interests.
                      Additionally, information regarding your usage may still be collected for
                      research, analytics or internal operations purposes.
                    </p>
                    <br />
                    <p>
                      <strong>California Privacy Rights</strong>
                    </p>
                    <br />
                    <p>
                      Under the California Consumer Privacy Act of 2018 (“CCPA”), California
                      residents have the right to request:
                    </p>
                    <br />
                    <ul>
                      <li>The deletion of the Personal Information we have about them.</li>
                      <li>
                        To know additional information about whether and how we have collected,
                        used, and disclosed Personal Information about them.
                      </li>
                      <li>To know specific pieces of Personal Information we have about them.</li>
                    </ul>
                    <p>
                      California residents also have the right not to receive discriminatory
                      treatment if they exercise the rights listed above.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Requests for Deletion and to Know About Information Collected:
                      </strong>
                      <br />
                      If you make a request for deletion of Personal Information or a request to
                      know about Personal Information we have collected, we may require that you
                      provide information and follow procedures so that we can verify the request
                      and your jurisdiction before responding to it. The verification steps we take
                      may differ depending on the request you make. We will match the information
                      that you provide in your request to information we already have on file to
                      verify your identity. If we are able to verify your request, we will process
                      it. If we cannot verify your request, we may ask you for additional
                      information to help us verify your request.
                    </p>
                    <br />
                    <p>
                      California residents will be required to submit their first and last name and
                      email address and may also be asked to provide their telephone number or
                      address so that we can verify the request. Please provide as much of the
                      requested information as possible to help us verify the request. We will only
                      use the information received in a request for the purposes of responding to
                      the request.
                    </p>
                    <br />
                    <p>
                      California law permits California residents to use an authorized agent to make
                      privacy rights requests. We require the authorized agent to provide proof of
                      the California resident’s written permission (for example, a power of
                      attorney) that shows the authorized agent has the authority to submit a
                      request for the California resident. An authorized agent must follow the
                      process described below to make a request. We will additionally require the
                      authorized agent to verify his/her own identity.
                    </p>
                    <br />
                    <p>
                      <strong>You may submit a request to us by:</strong>
                    </p>
                    <ul>
                      <li>
                        Emailing us at <a href='mailto:info@SOUNDCTRL.xyz'>info@SOUNDCTRL.xyz</a>
                      </li>
                    </ul>
                    <br />
                    <p>
                      <strong>Other California Requirements:</strong> If you are a California
                      resident under the age of 18, and a registered user of any site where this
                      policy is posted, you may request and obtain removal of content or information
                      you have publicly posted to the SOUNDCTRL Tech INC by contacting us at{" "}
                      <a href='mailto:intro@SOUNDCTRL.xyz'>intro@SOUNDCTRL.xyz</a>.
                    </p>
                    <br />
                    <p>
                      SOUNDCTRL does not currently take actions to respond to “Do Not Track”
                      signals.
                    </p>
                    <br />
                    <p>
                      <strong>Nevada Privacy Rights</strong>
                    </p>
                    <br />
                    <p>
                      We do not sell consumers’ covered information for monetary consideration (as
                      defined in Chapter 603A of the Nevada Revised Statutes). However, if you are a
                      Nevada resident, you have the right to submit a request directing us not to
                      sell your Personal Information. To submit such a request, please contact us
                      using the information in the “How to Contact Us” section below.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>
                      Information on the SOUNDCTRL Tech INC
                    </span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      SOUNDCTRL is a United States company and the SOUNDCTRL Tech INC are provided
                      from the United States. If you are a user who resides outside the United
                      States, please be aware that information we collect will be transferred to and
                      processed in the United States and may be processed and stored in other
                      countries outside the United States which may have data protection laws that
                      differ from the laws in your country. By using the SOUNDCTRL Tech INC, or
                      providing us with any information, you consent to the collection, processing,
                      maintenance and transfer of such information in and to the United States and
                      other applicable countries in which the privacy laws may not be as
                      comprehensive as, or equivalent to, those in the country where you reside
                      and/or are a citizen.
                    </p>
                    <br />
                    <p>
                      We maintain procedural, technical, and physical safeguards to help protect
                      against loss, misuse or unauthorized access, disclosure, alteration, or
                      destruction of the Personal Information you provide via the SOUNDCTRL Tech
                      INC. Unfortunately, no transmission of data is guaranteed to be completely
                      secure. It may be possible for third parties not under our control to
                      intercept or access transmissions or private communications unlawfully.
                      Therefore, any transmission of Personal Information to us is made at your own
                      risk.
                    </p>
                    <br />
                    <p>
                      We retain your account information and the information provided by you in the
                      form of Posts indefinitely until such time that we determine it has no further
                      commercial significance, at which time we may delete and/or aggregate and
                      store it indefinitely. We may also retain and use this information as
                      necessary to comply with our legal obligations, resolve disputes, and enforce
                      our agreements.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>
                      How We Protect and Retain Your Information
                    </span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      We maintain procedural, technical, and physical safeguards to help protect
                      against loss, misuse or unauthorized access, disclosure, alteration, or
                      destruction of the Personal Information you provide via the SOUNDCTRL Tech
                      INC. Unfortunately, no transmission of data is guaranteed to be completely
                      secure. It may be possible for third parties not under our control to
                      intercept or access transmissions or private communications unlawfully.
                      Therefore, any transmission of Personal Information to us is made at your own
                      risk.
                    </p>
                    <br />
                    <p>
                      We retain your account information and the information provided by you in the
                      form of Posts indefinitely until such time that we determine it has no further
                      commercial significance, at which time we may delete and/or aggregate and
                      store it indefinitely. We may also retain and use this information as
                      necessary to comply with our legal obligations, resolve disputes, and enforce
                      our agreements.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>Third Party Links</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      The SOUNDCTRL Tech INC may from time to time contain links to third party
                      websites/services for your convenience and/or information (for example, sites
                      linking via banner ads). If you access other sites using the links provided,
                      you may leave the SOUNDCTRL Tech INC. When you access these other sites,
                      please understand that the operators of these websites may collect information
                      from you which will be used by them in accordance with their privacy policies
                      and terms of use, which may differ from ours. We do not endorse or control the
                      policies or practices of third party websites that we do not control or
                      operate.
                    </p>
                    <br />
                    <p>
                      You should always carefully read the privacy policy of any site you access in
                      order to understand their specific privacy and information usage practices.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>Children’s Personal Information</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      Except where otherwise indicated, we do not knowingly collect or store any
                      Personal Information from children under the age of 13. If a parent or
                      guardian becomes aware that his or her child under the age of 13 has provided
                      us with information without his or her consent, he or she should contact us at
                      info@soundctrl.xyz. As required by law, we will delete such information from
                      our files within a reasonable time.
                    </p>
                    <br />
                    <p>
                      We may also delete Personal Information from our websites, as well as Posts
                      and other information, from users we believe are under the age of 18.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>Updates to this Privacy Policy</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      From time to time, we may revise this Policy to reflect changes in the law or
                      technology, or changes in our practices regarding information collection and
                      use. If we make material revisions to the way we collect or use your Personal
                      Information so that we are using it for purposes or sharing data with third
                      parties that you have not consented to, we will provide you with notice of
                      those changes and may do so by announcing the change through the SOUNDCTRL
                      Tech INC. You agree that we may notify you about material changes in the way
                      we treat Personal Information by placing a notice on the SOUNDCTRL Tech INC.
                      You should check our online services frequently for updates.
                    </p>
                    <br />
                    <p>
                      Any changes to this Policy will become effective as of the Effective Date on
                      the top of this page. By continuing to use the SOUNDCTRL Tech INC after the
                      most current Effective Date, you will be deemed to have understood and agreed
                      to such changes. If you do not agree with the terms of this Policy, as it may
                      be amended from time to time, in whole or in part, please do not continue to
                      use the SOUNDCTRL Tech INC.
                    </p>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ textAlign: "center", fontSize: "1.5rem", margin: 15 }}>
                    <span style={{ fontWeight: "bold" }}>Additional Terms</span>
                  </div>
                }
                secondary={
                  <>
                    <p>
                      This Policy may be supplemented from time to time by additional terms, privacy
                      statements, or notices that explain how we collect, use and share information
                      in specific circumstances (“Additional Terms”). For example, Additional Terms
                      may contain details about how we collect Personal Information in specific
                      contexts.
                    </p>
                    <br />
                    <p>How to Contact Us</p>
                    <br />
                    <p>
                      If you have any questions or suggestions regarding this Privacy Policy,
                      contact us at <a href='mailto:info@SOUNDCTRL.xyz'>info@SOUNDCTRL.xyz</a>.
                    </p>
                  </>
                }
              />
            </ListItem>
          </List>
        </Section>
      </Section>
    </Container>
  );

interface SectionProps {
  title: string;
  children: React.ReactNode;
  fontSize?: string;
}

const Section: React.FC<SectionProps> = ({ title, children, fontSize }) => (
  <Box mb={4}>
    <Typography variant='h2' component='h2' gutterBottom sx={{ fontSize }}>
      {title}
    </Typography>
    {children}
  </Box>
);

export default PrivacyPolicy;
