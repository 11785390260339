import { useLocation, Link, useNavigate } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container } from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
// utils
import cssStyles from "../../utils/cssStyles";
// config
import { HEADER } from "../../config";
// components
// import { Logo } from "../../assets";

//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logoutUserFailure, logoutUserStart, logoutUserSuccess } from "../../redux/slice/UserSlice";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Logo from "components/Logo";

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: "calc(100% - 48px)",
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const dispatch = useAppDispatch();
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "md");
  const isHome = pathname === "/";

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, []);

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(logoutUserStart());
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/logout`);
      axios.defaults.headers.common.Authorization = "";
      localStorage.removeItem("access_token");
      dispatch(logoutUserSuccess());
      navigate("/login");
    } catch (err) {
      dispatch(logoutUserFailure());
      toast.error("Logout failed. Please try again.");
    }
  };

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        background:
          "linear-gradient(90deg, rgba(255,169,249,1) 0%, rgba(255,249,121,1) 100%, rgba(255,247,173,1) 100%)",
        transition: theme.transitions.create(["background-color", "height"], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter,
        }),
        ...(isOffset && {
          ...cssStyles(theme).bgBlur(),
          // Slightly transparent background when scrolled
          backgroundColor: "rgba(255,169,249,0.8)",
          height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
        }),
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "fixed",
              left: { xs: 20, md: 80 },
              // background:
              //   "linear-gradient(90deg, rgba(255,169,249,1) 0%, rgba(255,249,121,1) 100%, rgba(255,247,173,1) 100%)", // Gradient applied only to the logo section
              // Adjust padding as needed to create visual balance
              padding: "8px",
              // Optional: Add a slight border-radius for a smooth effect
              borderRadius: "8px",
            }}
          >
            <Link to='/' style={{ textDecoration: "none" }}>
              <Logo />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {isAuthenticated &&
            (isDesktop ? (
              <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            ) : (
              <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            ))}

          {!isAuthenticated ? (
            <Link to='/login'>
              <Button
                variant='text'
                sx={{
                  color: "text.primary",
                  typography: "subtitle2",
                }}
              >
                LOG IN
              </Button>
            </Link>
          ) : (
            <Button
              variant='text'
              sx={{
                color: "text.primary",
                typography: "subtitle2",
              }}
              onClick={handleLogout}
            >
              LOG OUT
            </Button>
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
