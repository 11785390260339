import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WaitlistProps } from "@types";

const initialState = {
    waitlist: [] as WaitlistProps[],
    isLoading: false,
    error: false
}

const waitlistSlice = createSlice({
    name: "waitlist",
    initialState,
    reducers: {
        getWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        getWaitlistSuccess: (state, action: PayloadAction<WaitlistProps[]>) => {
            state.waitlist = action.payload;
            state.isLoading = false;
        },
        getWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        },
        addWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        addWaitlistSuccess: (state, action: PayloadAction<WaitlistProps>) => {
            state.waitlist.push(action.payload);
            state.isLoading = false;
        },
        addWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        },
        updateWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        updateWaitlistSuccess: (state, action: PayloadAction<WaitlistProps>) => {
            state.waitlist[
                state.waitlist.findIndex((item) => item._id === action.payload._id)
            ] = action.payload;
            state.isLoading = false;
        },
        updateWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        },
        deleteWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        deleteWaitlistSuccess: (state, action) => {
            state.waitlist.splice(
                state.waitlist.findIndex((item) => item._id === action.payload._id), 1
            );
            state.isLoading = false;
        },
        deleteWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        }
    }
});

export const {
    getWaitlistFailure,
    getWaitlistStart,
    getWaitlistSuccess,
    updateWaitlistFailure,
    updateWaitlistSuccess,
    updateWaitlistStart,
    addWaitlistFailure,
    addWaitlistStart,
    addWaitlistSuccess,
    deleteWaitlistFailure,
    deleteWaitlistStart,
    deleteWaitlistSuccess,
} = waitlistSlice.actions;

export default waitlistSlice.reducer;